@import '../../../assets/scss/variables';

.linear-progress-bar {
  position: relative;
  width: 80%;
  padding: 8px 24px;
}

.linear-progress-bar-track {
  position: relative;
  width: 100
}

.linear-progress-bar-fill {
  position: absolute;
  top: 48%;
  left: 0;
  background-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.linear-progress-bar-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $gray-1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #fff;

  &--active {
    background-color: #2196f3;
  }
}

.icon-text {
  color: $black-primary;
}

.dark-theme {
  .icon-text {
    color: $white-primary;
  }
}