.banner-container {
  width: 21.375rem;
  height: 21.875rem;
  display: flex;
  padding: 1rem 0rem;
  flex-direction: column;
  text-align: center;
  gap: 2rem;

  border-radius: 0.5rem;
  border: 2px solid #D9D9DF;
  background: #F4F4F4;
}

.banner-title {
  color: #1A1A1F;
  font-family: 'neue-haas-grotesk-display';
  font-size: 1.5rem;
  font-weight: 700;
  width: 18.875rem;
}

.banner-body {
  color: #1A1A1F;
  font-family: 'neue-haas-grotesk-display';
  font-size: 1rem;
  width: 18.875rem;
}