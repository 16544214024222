@import './variables';

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: .5;

  &.btn::before {
    display: inline-block;
    content: "";
  }
}

// Animation
.placeholder-glow {
  .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}
