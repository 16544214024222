@import './variables';

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  @extend %body2;
  border-radius: 8px;
  padding: 12px 24px;
  border: 0;
  width: fit-content;
  box-shadow: 0 0 0 1px $gray-1 inset;
  background-color: $white;
  color: $black-primary;

  &.btn-sticky-all {
    border-radius: 0;
  }

  @media (max-width: $size-md + 'px') {
    &.btn-sticky-sm {
      border-radius: 0 !important;
    }
  }

  @media (min-width: $size-md + 'px') {
    &.btn-sticky-lg {
      border-radius: 0 !important;
    }
  }

  &:hover, &.hover {
    color: $black-secondary;
    box-shadow: 0 0 0 1px $gray-2 inset;
    text-decoration: none;
    border-radius: 4px;
  }

  &:active, &.active {
    background: $gray-4;
    color: $black-primary;
    box-shadow: 0 0 0 1px $gray-1 inset;
    border-radius: 16px;
  }

  &:disabled, &.disabled {
    pointer-events: none;
    background: $gray-5;
    color: $black-disabled;
    box-shadow: none;
    border-radius: 8px;
  }

  &:focus, &.focus {
    outline: 0;
    color: $black-primary;
    box-shadow: 0 0 0 2px $gray-1 inset;
    border-radius: 12px;
  }
}

.icon-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  svg {
    fill: $black-primary;
    height: 18px;
    width: 16px;
  }

  &:hover, &.hover {
    border-radius: 20px;
    svg {
      fill: $black-secondary;
    }
  }

  &:active, &.active {
    border-radius: 20px;
    svg {
      fill: $black-primary;
    }
  }

  &:disabled, &.disabled {
    border-radius: 20px;
    svg {
      fill: $black-disabled;
    }
  }

  &:focus, &.focus {
    border-radius: 20px;
    svg {
      fill: $black-primary;
    }
  }
}

.dark-theme {
  .btn {
    color: $white-primary;
    background-color: $gray-1;

    &:hover, &.hover {
      color: $white-secondary;
      background-color: $gray-2;
    }

    &:active, &.active {
      color: $white-primary;
      background-color: $gray-1;
    }

    &:disabled, &.disabled {
      color: $white-disabled;
      background-color: $gray-2;
    }

    &:focus, &.focus {
      color: $white-primary;
      background-color: $gray-1;
    }
  }

  .icon-btn {
    svg {
      fill: $white-primary;
    }

    &:hover, &.hover {
      svg {
        fill: $white-secondary;
      }
    }

    &:active, &.active {
      svg {
        fill: $white-primary;
      }
    }

    &:disabled, &.disabled {
      svg {
        fill: $white-disabled;
      }
    }

    &:focus, &.focus {
      svg {
        fill: $white-primary;
      }
    }
  }
}

.btn.btn-primary {
  color: $white-primary;
  background-color: $blue-2;
  box-shadow: none;

  &:hover, &.hover {
    color: $white-secondary;
    background-color: $blue-3;
    box-shadow: none;
  }

  &:active, &.active {
    color: $white-primary;
    background-color: $blue-1;
    box-shadow: none;
  }

  &:disabled, &.disabled {
    color: $white-disabled;
    background-color: $blue-4;
    box-shadow: none;
  }

  &:focus, &.focus {
    color: $white-primary;
    background-color: $blue-2;
    box-shadow: 0 0 0 2px $gray-1 inset;
  }
}

.icon-btn.btn-primary {
  color: $white-primary;
  background-color: $blue-2;
  box-shadow: none;

  svg {
    fill: $white-primary;
  }

  &:hover, &.hover {
    color: $white-secondary;
    background-color: $blue-3;
    box-shadow: none;

    svg {
      fill: $white-secondary;
    }
  }

  &:active, &.active {
    color: $white-primary;
    background-color: $blue-1;
    box-shadow: none;

    svg {
      fill: $white-primary;
    }
  }

  &:disabled, &.disabled {
    color: $white-disabled;
    background-color: $blue-4;
    box-shadow: none;

    svg {
      fill: $white-disabled;
    }
  }

  &:focus, &.focus {
    color: $white-primary;
    background-color: $blue-2;
    box-shadow: 0 0 0 2px $gray-1 inset;

    svg {
      fill: $white-primary;
    }
  }
}
