.car-instructions-container {
  padding: 1.5rem;
  // justify-content: space-between;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header__title {
  font-family: 'neue-haas-grotesk-display';
  font-weight: 500;
  color: #999;
  font-size: 1rem;
}

.timer {
  position: relative;
  background-color: #FFF;
  width: 8rem;
  height: 2rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
}

.title {
  font-family: 'neue-haas-grotesk-display';
  font-weight: 500;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.88);
  line-height: 2.5rem;
}

.title__subtext {
  font-family: 'neue-haas-grotesk-display';
  font-weight: 500;
  font-size: 0.875rem;
  color: #999;
}

.progress-bar {
  display: flex;
  padding: 1.25rem 0rem 0rem 0.625rem;
  justify-content: flex-end;
  align-items: flex-start;
}

.progress-bar__step {}

.progress-bar__step-number {
  width: 2.25rem;
  height: 2.25rem;
  background-color: #FFF;
  border-color: #76CCCC;
  border-width: 2px;
  border-radius: 20px;
  border-style: solid;
  text-align: center;
}

.progress-bar__step-text {}

.next-button {}

.slick-slider {
  margin-inline: -10px; //  space between slides * (-1)
}

.slick-slide {
  >div {
    // automatically generated 'div' by 'react-slick'
    margin-inline: 5px; //  space between slides / 2
  }
}