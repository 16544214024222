@import '../../../assets/scss/variables';

@media (max-width: $size-md + 'px') {
  .car-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: $size-md + 'px') {
  .car-details-container {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: 'relative';
    overflow-y: scroll;
  }
}

@media (min-width: $size-xl + 'px') {
  .car-details-container {
    border-left: 1px solid #f5f5f5;
  }
}