@import '../../../assets/scss/variables';

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  .back-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    margin-right: 16px;
    cursor: pointer;
    background: $gray-5;
  }
}

.dark-theme {
  .page-title {
    .back-btn {
      background: $gray-1;
    }
  }
}