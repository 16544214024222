@import './assets/scss/variables';

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.app-map {
  display: flex;
  flex-grow: 1;

  .map-box {
    height: 100%;
    position: relative;
    background-color: #E5E3DF;
  }
}

.app-data {
  background: $white;
  display: flex;
  flex-direction: column;

  .scrollable {
    padding: 0 24px;
  }
}

@media (max-width: $size-md + 'px') {
  .app-map {
    height: 390px;
  }

  .app-data {
    display: flex;
    width: 100%;
  }
}

@media (min-width: $size-md + 'px') {
  .app-map {
    height: 100vh;

    .map-box {
      height: 100vh;
    }
  }

  .app-data {
    display: flex;
    height: 100vh;
    max-height: 100vh;
  }

  .scrollable {
    overflow-y: scroll;
  }
}

.dark-theme {
  .map-box {
    background-color: $black;
  }

  .app-data {
    background: $black;
  }
}