.checkin-linear-progress-bar {
  position: relative;
  width: 100%;
  padding: 0px 16px;
  z-index: 0;
}

.checkin-linear-progress-bar-track {
  position: relative;
  width: 100%;
}

.checkin-linear-progress-bar-fill {
  position: absolute;
  top: 48%;
  left: 0;
  background-color: #1A1A1F;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 1;
}

.checkin-linear-progress-bar-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  // bottom: 0;
  // margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  border-style: solid;
  z-index: 3;
  border-width: 2px;

  &--active {
    border-color: #AAAAC4;
    background-color: #FFF;
    border-width: 2px;
    width: 40px;
    height: 40px;
  }

  &--completed {
    background-color: #06C167;
    color: #06C167;
    width: 32px;
    height: 32px;
  }

  &--incompleted {
    border-color: #D9D9DF;
    background-color: #F4F4F4;
    width: 32px;
    height: 32px;
  }
}

.checkin-linear-progress-bar-icon-svg {
  &--active {
    width: 24px;
    height: 24px;
  }

  &--completed {
    width: 20px;
    height: 20px;
  }

  &--incompleted {
    width: 18px;
    height: 18px;
  }
}

.icon-text {
  color: #1A1A1F;
  font-family: 'sf-pro';
}