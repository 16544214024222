.confirm-select-card {
  display: flex;
  width: 21.375rem;
  height: 21.875rem;
  min-height: 21.875rem;
  max-height: 21.875rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.5rem;
}

.confirm-select-card__textbox-container {
  display: flex;
  padding: 1.25rem 1.875rem 0.625rem 1.875rem;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;

  border-radius: 0rem 0rem 0.5rem 0.5rem;
  background: #2B2B3E;
  background: rgba(43, 43, 62, 0.95);
  background-blend-mode: hard-light;
  backdrop-filter: blur(25px);
}

.confirm-select-card__textbox-container-body {
  color: #FFF;
  font-family: "neue-haas-grotesk-display";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  white-space: pre-line;
}

.confirm-select-card__textbox-container-footer {
  display: flex;
  padding-top: 0.625rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-top: 1px solid #7777A0;
}