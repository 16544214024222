.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-prev,
.slick-next {
  width: 70px;
  height: 70px;
  z-index: 1000;
}

.slick-track {
  display: flex;
  text-align: center;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.slick-dots {
  position: relative;
  bottom: 0px;
}