.select-card {
  display: flex;
  width: 18.75rem;
  height: 22rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.5rem;
}

.select-card__textbox-container {
  display: flex;
  padding: 1.25rem 1.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;

  border-radius: 0rem 0rem 0.5rem 0.5rem;
  background: #FFF;
  background-blend-mode: hard-light;
  backdrop-filter: blur(25px);

  &.active {
    background: #1A1A1F;
  }
}

.select-card__textbox-container-title {
  color: #000;
  font-family: "neue-haas-grotesk-display";
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  gap: 0.3rem;

  &.active {
    color: #FFF;
  }
}

.select-card__textbox-container-body {
  display: flex;
  padding-top: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-top: 1px solid #76CCCC;

  color: #1A1A1F;
  font-family: "neue-haas-grotesk-display";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;

  &.active {
    color: #FFF;
  }
}