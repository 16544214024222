@import './variables';

.input-field {
  width: 100%;
  text-align: left;
  white-space: nowrap;

  .group {
    position: relative;
  }

  .label {
    position: absolute;
    @extend %body2;
    left: 16px;
    top: 16px;
    color: $black-secondary;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;

    &.active {
      color: $black-disabled;
      @extend %body3;
      line-height: 16px;
      transform: translateY(-8px);
    }
  }

  .icon {
    position: absolute;
    fill: $black-secondary;
    margin: 16px;

    &.start {
      left: 0;
    }

    &.end {
      right: 0;
    }
  }

  &:hover,
  &.hover {
    .input {
      box-shadow: 0 0 0 1px $gray-2 inset;
    }
  }

  &.error {
    .input {
      box-shadow: 0 0 0 1px $red-1 inset;
    }

    .icon {
      fill: $black-primary;
    }
  }

  &.readonly {
    pointer-events: none;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;

    .label {
      color: $black-disabled;
    }

    .input {
      color: $black-disabled;
      background: $gray-5;
      box-shadow: none;
    }

    .icon {
      fill: $black-disabled;
    }
  }

  .input {
    @extend %body2;
    padding: 16px;
    border-radius: 8px;
    border: 0;
    color: $black-primary;
    background: $gray-5;
    width: 100%;

    &::placeholder {
      color: transparent;
    }

    &.has-start {
      padding-left: 56px;

      &+.label {
        left: 56px;
      }

    }

    &.has-end {
      padding-right: 56px;
    }

    &:active,
    &.active,
    &:focus,
    &.focus {
      background: $white;
      box-shadow: 0 0 0 1px $gray-1 inset;

      &+label {
        color: $black-primary;
      }

      &+label+.icon,
      &+label+.icon+.icon {
        fill: $black-primary;
      }
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px $gray-1 inset;
    }

    &:focus,
    &:not(:placeholder-shown),
    &:-webkit-autofill {
      padding-top: 24px;
      padding-bottom: 8px;

      &+.label {
        color: $black-disabled;
        @extend %body3;
        line-height: 16px;
        transform: translateY(-8px);
      }
    }
  }

  .error-msg {
    @extend %body2;
    color: $red-1;
  }
}

input[type="checkbox"],
input[type="radio"] {
  height: 0;
  width: 0;
  cursor: pointer;

  &:before,
  &:after {
    display: block;
    position: absolute;
    content: "";
    border: 0;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
  }

  &:before {
    box-shadow: 0 0 0 1px $gray-4 inset;
    background: $white;
    height: 24px;
    width: 24px;
  }

  &:after {
    margin: 6px;
    height: 12px;
    width: 12px;
  }

  &:hover:before {
    box-shadow: 0 0 0 1px $gray-2 inset;
  }

  &:active:before {
    box-shadow: 0 0 0 1px $gray-1 inset;
    background: $gray-4;
  }

  &:disabled:before {
    box-shadow: 0 0 0 1px $gray-4 inset;
    background: $gray-5;
  }

  &:focus:before {
    box-shadow: 0 0 0 2px $gray-2 inset;
    background: $white;
  }
}

input[type="checkbox"] {
  &:before {
    border-radius: 4px;
  }

  &:checked:before,
  &:checked:active:before {
    box-shadow: none;
    background: $gray-1 url("../icons/checkbox-white.svg");
  }

  &:checked:hover:before {
    box-shadow: none;
    background: $gray-2 url("../icons/checkbox-white.svg");
  }

  &:checked:disabled:before {
    box-shadow: 0 0 0 1px $gray-4 inset;
    background: $gray-5 url("../icons/checkbox-white.svg");
  }

  &:checked:focus:before {
    box-shadow: 0 0 0 2px $gray-2 inset;
    background: $gray-1 url("../icons/checkbox-white.svg");
  }
}

input[type="radio"] {

  &:before,
  &:after {
    border-radius: 50%;
  }

  &:checked:after,
  &:checked:active:after {
    background: $gray-1;
  }

  &:checked:hover:after {
    background: $gray-2;
  }

  &:checked:disabled:after {
    background: $gray-4;
  }
}

input[type="search"] {
  &::-webkit-search-cancel-button {
    position: relative;
    -webkit-appearance: none;
    background-image: url("../icons/close.svg");
    height: 24px;
    width: 24px;
    transform: scale(.75);
    cursor: pointer;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
}

.dark-theme {
  .input-field {
    .label {
      color: $white-secondary;

      &.active {
        color: $white-disabled;
      }
    }

    .icon {
      fill: $white-secondary;
    }

    &:hover,
    &.hover {
      .input {
        box-shadow: 0 0 0 1px $gray-4 inset;
      }
    }

    &.error {
      .icon {
        fill: $white-primary;
      }
    }

    &:disabled,
    &.disabled {
      .label {
        color: $white-disabled;
      }

      .input {
        color: $white-disabled;
        background: $gray-1;
      }

      .icon {
        fill: $white-disabled;
      }
    }

    .input {
      color: $white-primary;
      background: $gray-1;

      &:active,
      &.active,
      &:focus,
      &.focus {
        background: $black;
        box-shadow: 0 0 0 1px $gray-5 inset;

        &+label {
          color: $white-primary;
        }

        &+label+.icon,
        &+label+.icon+.icon {
          fill: $white-primary;
        }
      }

      &:focus,
      &.focus {
        box-shadow: 0 0 0 2px $gray-5 inset;
      }

      &:focus,
      &:not(:placeholder-shown),
      &:-webkit-autofill {
        &+.label {
          color: $white-disabled;
        }
      }
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    &:before {
      box-shadow: 0 0 0 1px $gray-2 inset;
      background: $black;
    }

    &:hover:before {
      box-shadow: 0 0 0 1px $gray-4 inset;
    }

    &:active:before {
      box-shadow: 0 0 0 1px $gray-5 inset;
      background: $gray-2;
    }

    &:disabled:before {
      box-shadow: 0 0 0 1px $gray-2 inset;
      background: $gray-1;
    }

    &:focus:before {
      box-shadow: 0 0 0 2px $gray-4 inset;
      background: $black;
    }
  }

  input[type="checkbox"] {
    &:checked:before {
      box-shadow: none;
      background: $white url("../icons/checkbox-black.svg");
    }

    &:checked:hover:before {
      box-shadow: 0 0 0 1px $gray-2 inset;
      background: $white url("../icons/checkbox-black.svg");
    }

    &:checked:active:before {
      box-shadow: 0 0 0 1px $gray-2 inset;
      background: $gray-4 url("../icons/checkbox-black.svg");
    }

    &:checked:disabled:before {
      box-shadow: none;
      background: $gray-2 url("../icons/checkbox-black.svg");
    }

    &:checked:focus:before {
      box-shadow: 0 0 0 2px $gray-2 inset;
      background: $white url("../icons/checkbox-black.svg");
    }
  }

  input[type="radio"] {

    &:checked:after,
    &:checked:active:after {
      background: $gray-5;
    }

    &:checked:hover:after {
      background: $gray-4;
    }

    &:checked:disabled:after {
      background: $gray-2;
    }
  }
}