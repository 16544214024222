@import '../../../assets/scss/variables';

.cars-list-scroll {
  flex-grow: 1;
  height: 100vh;
}

@media (min-width: $size-sm + 'px') {
  .cars-list-scroll {
    padding-bottom: 100px !important;
  }
}