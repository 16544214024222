@import './variables';

.dark-theme {
  color: $white-primary;
  background-color: $black;

  .svg-icon {
    fill: $white-primary;
  }

  .placeholder-text {
    background-color: $white;
  }
}
