@import 'assets/scss/variables';

th {
  padding: 10px;
}

tr {
  border-bottom: 1px solid $gray-2 !important;
}

td {
  padding: 10px;
}