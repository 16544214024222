.questionnaire {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #F4F4F4;
  width: 21.4rem;
  height: 21.875rem;
  border-color: #D9D9DF;
  border-style: solid;
  border-width: 2px;
  padding: 1.5rem 1.875rem;
  justify-content: space-between;
}

.questionnaire__title {
  color: #1A1A1F;
  font-family: 'neue-haas-grotesk-display';
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 1rem;
  border-bottom-color: #76CCCC;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  text-align: left;
}

.questionnaire__option {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 24px;
  border-color: #7777A0;
  border-style: solid;
  border-width: 2px;
}

.questionnaire__option--selected {
  width: 25px;
  height: 25px;
  background-color: #67CE67;
  border-radius: 25px;
  border-color: #67CE67;
  border-style: solid;
}

.questionnaire__option-text {
  text-align: left;
  color: #999;
  font-family: "neue-haas-grotesk-display";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}