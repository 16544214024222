@import './variables';

.filter {
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 28px;
  column-gap: 8px;
  user-select: none;
  cursor: pointer;
  border: 0;
  color: $black-primary;
  box-shadow: 0 0 0 1px $gray-4 inset;
  background-color: $white;
  max-height: 56px;

  .icon {
    height: 16px;
    width: 16px;
    fill: $black-primary;
  }

  &:hover,
  &.hover {
    box-shadow: 0 0 0 1px $gray-2 inset;
  }

  &:active,
  &.active {
    box-shadow: 0 0 0 1px $gray-1 inset;
  }

  &:disabled,
  &.disabled {
    box-shadow: none;
    color: $black-disabled;
    background: $gray-5;
    pointer-events: none;

    .icon {
      fill: $black-disabled;
    }
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px $gray-1 inset;
  }

  .icon-selected {
    background-color: $gray-1;
    color: $white-primary;
    border-radius: 50%;
    text-align: center;
    @extend %body3;
  }

  .filter-dropdown {
    background: $white;
    box-shadow: 0 0 0 2px $gray-1 inset;
    position: fixed;
    padding: 16px;
    padding-bottom: 0px;
    z-index: 12;
    cursor: default;
    transition: bottom .3s ease-in-out;
    width: 100%;
    height: max-content;
    max-height: 80vh;
    left: 0;
    bottom: -100vh;
  }

  .filter-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 11;
    display: none;
    cursor: default;
  }

  @media (min-width: $size-md + 'px') {
    position: relative;

    .filter-dropdown {
      width: 300px;
      max-height: 560px;
      transition: none;

      &.left {
        border-radius: 0 16px 16px 16px;
      }

      &.right {
        border-radius: 16px 0 16px 16px;
      }
    }
  }

  &.show {
    box-shadow: 0 0 0 1px $gray-2 inset;

    @media (min-width: $size-md + 'px') {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:after {
        content: '';
        position: absolute;
        background: inherit;
        width: 100%;
        height: 13px;
        bottom: -11px;
        left: 0;
        border-left: 1px solid $gray-2;
        border-right: 1px solid $gray-2;
        z-index: 12;
      }

    }

    .filter-dropdown {
      display: flex;
      background: $white;
      box-shadow: none;

      @media (min-width: $size-md + 'px') {
        box-shadow: 0 0 0 1px $gray-1 inset;
      }
    }

    .filter-backdrop {
      display: block;

      @media (min-width: $size-md + 'px') {
        display: none;
      }
    }
  }
}

.dark-theme {
  .filter {
    color: $white-primary;
    box-shadow: 0 0 0 1px $gray-2 inset;
    background-color: $black;

    .icon {
      fill: $white-primary;
    }

    &:hover,
    &.hover {
      box-shadow: 0 0 0 1px $gray-4 inset;
    }

    &:active,
    &.active {
      box-shadow: 0 0 0 1px $gray-5 inset;
    }

    &:disabled,
    &.disabled {
      box-shadow: 0 0 0 2px $gray-1 inset;
      color: $white-disabled;
      background: $gray-1;

      .icon {
        fill: $white-disabled;
      }
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px $gray-5 inset;
    }

    .icon-selected {
      background-color: $gray-5;
      color: $black-primary;
    }

    .filter-dropdown {
      background: $black;
      box-shadow: none;
    }

    .filter-backdrop {
      background: rgba(0, 0, 0, 0.7);
    }

    &.show {
      box-shadow: 0 0 0 1px $gray-4 inset;

      .filter-dropdown {
        background: $black;
        box-shadow: none;

        @media (min-width: $size-md + 'px') {
          box-shadow: 0 0 1px $gray-4 inset;
        }
      }
    }
  }
}