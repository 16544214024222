@mixin font-face($font-family, $font-file, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url('#{$font-file}') format('opentype');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face('sf-pro', '../../../public/fonts/sf-pro/regular.otf', 400, normal);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/medium.otf', 500, normal);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/bold.otf', 700, normal);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/bold.otf', 800, normal);

@include font-face('sf-pro', '../../../public/fonts/sf-pro/ultralight-italic.otf', 100, italic);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/thin-italic.otf', 200, italic);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/light-italic.otf', 300, italic);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/black-italic.otf', normal, italic);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/semibold-italic.otf', 600, italic);
@include font-face('sf-pro', '../../../public/fonts/sf-pro/heavy-italic.otf', 700, italic);

@import './variables';
@import './dark';
@import './reboot';
@import './typography';
@import './grid';
@import './buttons';
@import './links';
@import './navigations';
@import './placeholders';
@import './forms';
@import './chips';
@import './filters';
@import './tabs';
@import './card';
@import './images';

.a:visited {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
}