@import '../../assets/scss/variables';

@media (min-width: $size-md + 'px') {
  .checkout-container {
    max-width: 400px;
  }
}

.checkout-container {
  display: flex;
  flex-direction: column;
  width: '100% !important';
}