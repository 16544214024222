@import './variables';

.card {
  display: block;
  position: relative;
  background: $gray-5;
  color: $black-primary;
  text-align: left;
  border: 0;
  width: 100%;
  padding: 16px;

  &.radius-small {
    border-radius: 8px;
  }

  &.radius-large {
    border-radius: 20px;
  }

  &.as-checkbox, &.as-radio, &.as-button {
    cursor: pointer;
  }

  &.as-checkbox, &.as-radio {
    padding-left: 56px;
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute;
    top: 16px;
    left: 16px;
    -webkit-appearance: none;
  }

  &.hover:not(.hover-disabled),
  &.as-checkbox:hover:not(.hover-disabled),
  &.as-radio:hover:not(.hover-disabled),
  &.as-button:hover:not(.hover-disabled) {
    box-shadow: 0 0 0 1px $gray-4 inset;
    background: $white;
  }

  &.active:not(.active-disable),
  &.as-checkbox:active:not(.active-disable),
  &.as-radio:active:not(.active-disable),
  &.as-button:active:not(.active-disable) {
    box-shadow: none;
    background: $gray-4;
  }

  &.disabled,
  &.as-checkbox:disabled, &.as-radio:disabled, &.as-button:disabled {
    box-shadow: none;
    background: $gray-5;
    color: $black-disabled;
    pointer-events: none;

    svg {
      fill: $black-disabled;
    }
  }

  &.focus:not(.focus-disable),
  &.as-checkbox:focus:not(.focus-disable),
  &.as-checkbox:focus-within:not(.focus-disable),
  &.as-radio:focus:not(.focus-disable),
  &.as-radio:focus-within:not(.focus-disable),
  &.as-button:focus:not(.focus-disable),
  &.as-button:focus-within:not(.focus-disable) {
    box-shadow: 0 0 0 2px $gray-2 inset;
    background: $gray-5;
  }
}

.dark-theme {
  .card {
    background: $gray-1;
    color: $white-primary;

    &.hover:not(.hover-disabled),
    &.as-checkbox:hover:not(.hover-disabled),
    &.as-radio:hover:not(.hover-disabled),
    &.as-button:hover:not(.hover-disabled) {
      box-shadow: 0 0 0 1px $gray-5 inset;
      background: $black;
    }

    &.active:not(.active-disable),
    &.as-checkbox:active:not(.active-disable),
    &.as-radio:active:not(.active-disable),
    &.as-button:active:not(.active-disable) {
      box-shadow: 0 0 0 1px $gray-5 inset;
      background: $gray-1;
    }

    &.disabled,
    &.as-checkbox:disabled, &.as-radio:disabled, &.as-button:disabled {
      box-shadow: none;
      background: $gray-1;
      color: $white-disabled;

      svg {
        fill: $white-disabled;
      }
    }

    &.focus:not(.focus-disable),
    &.as-checkbox:focus:not(.focus-disable),
    &.as-checkbox:focus-within:not(.focus-disable),
    &.as-radio:focus:not(.focus-disable),
    &.as-radio:focus-within:not(.focus-disable),
    &.as-button:focus:not(.focus-disable),
    &.as-button:focus-within:not(.focus-disable) {
      box-shadow: 0 0 0 2px $gray-5 inset;
      background: $black;
    }
  }
}
