@import '../../../assets/scss/variables';

@media (max-width: $size-md + 'px') {
  .filter-container {
    height: 568px !important;
    max-height: 568px !important;
    padding-bottom: 0px !important;
  }
}

.rdrCalendarWrapper {
  color: $black-primary;
  background: $white;
  @extend %body2;
  height: 300px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-4;

  &>div {
    height: inherit !important;
  }

  .rdrInfiniteMonths {
    height: 260 !important;
  }
}

.rdrWeekDays {
  border-bottom: 1px solid $gray-4;
  padding: 8px 0;
}

.rdrMonth {
  width: 100%;
  margin: 16px 0;
  overflow-x: hidden;

  .rdrMonthName {
    @extend %h3;
    text-align: left;
  }

  .rdrDays {
    margin-top: 8px;

    .rdrDay {
      user-select: none;
      position: relative;
      background: transparent;
      border: 0;
      padding: 0;
      height: 32px;
      text-align: center;

      &:focus {
        outline: 0;
      }

      .rdrDayNumber {
        z-index: 3;

        span {
          color: $black-primary !important;
        }
      }

      &.rdrDayToday .rdrDayNumber span {
        font-weight: 700;
      }

      &.rdrDayDisabled .rdrDayNumber span {
        color: $black-disabled !important;
        cursor: default;
      }

      .rdrStartEdge,
      .rdrEndEdge,
      .rdrDayStartPreview,
      .rdrDayEndPreview {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        width: 100%;
        background: $blue-5;

        &+.rdrDayNumber span,
        &+span+.rdrDayNumber span {
          color: $white-primary !important;
        }
      }

      .rdrStartEdge:before,
      .rdrEndEdge:before,
      .rdrDayStartPreview:before,
      .rdrDayEndPreview:before {
        position: absolute;
        content: "";
        top: 0;
        background: $blue-2;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        z-index: 2;
      }

      .rdrStartEdge,
      .rdrDayStartPreview {
        right: -50%;

        &:before {
          left: 0;
          transform: translate(-50%, 0);
        }
      }

      .rdrEndEdge,
      .rdrDayEndPreview {
        left: -50%;

        &:before {
          right: 0;
          transform: translate(50%, 0);
        }
      }

      .rdrStartEdge.rdrEndEdge,
      .rdrDayStartPreview.rdrDayEndPreview {
        background: transparent;
        left: 0;

        &:before {
          left: 50%;
          transform: translate(-50%, 0);
          right: auto;
        }
      }

      .rdrInRange,
      .rdrDayInPreview {
        position: absolute;
        top: 0;
        left: -50%;
        right: -50%;
        bottom: 0;
        background: $blue-5;
        z-index: 1;
      }
    }
  }
}


.dark-theme {
  .rdrCalendarWrapper {
    color: $white-primary;
    background: $black;
    border-bottom: 1px solid $gray-2;
  }

  .rdrWeekDays {
    border-bottom: 1px solid $gray-2;
  }

  .rdrMonth {
    .rdrDays {
      .rdrDay {
        .rdrDayNumber span {
          color: $white-primary !important;
        }

        &.rdrDayDisabled .rdrDayNumber span {
          color: $white-disabled !important;
        }

        .rdrStartEdge,
        .rdrEndEdge,
        .rdrDayStartPreview,
        .rdrDayEndPreview {
          background: $blue-5;
        }

        .rdrStartEdge.rdrEndEdge,
        .rdrDayStartPreview.rdrDayEndPreview {
          background: transparent;
        }

        .rdrInRange,
        .rdrDayInPreview {
          background: $blue-5;
        }
      }
    }
  }
}