@import '../../assets/scss/variables';

@media (min-width: $size-md + 'px') {
  .cars-parent-container {
    overflow-y: hidden;
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .cars-container {
    max-width: 360px;
  }
}