@import '../../../assets/scss/variables';

.container {
  border: 0.5px solid $gray-1;
  background-color: $black;
}

.dark-theme {
  .container {
    border: 1px solid $white;
    background-color: $black;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; // For Chrome, Safari and Opera
}