// Colors
$blue-1: hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 10%));
$blue-2: hsl(var(--primary-h), var(--primary-s), var(--primary-l)); // primary
$blue-3: hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%));
$blue-4: hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 40%));
$blue-5: hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 50%));

$gray-1: #1f1f1f;
$gray-2: #5c5c5c;
$gray-3: #999999;
$gray-4: #e0e0e0;
$gray-5: #f5f5f5;

$green-1: #26A96C;
$green-2: #74C8A1;
$green-3: #A8DDC4;
$green-4: #E5F5ED;
$green-5: #F6FCF9;

$red-1: #E53D00;
$red-2: #EE835C;
$red-3: #F5B199;
$red-4: #FCE8E0;
$red-5: #FEF7F5;
$red-6: #FFC1C1;

$black: #000000;
$white: #ffffff;

$black-primary: rgba(0, 0, 0, 0.88);
$black-secondary: rgba(0, 0, 0, 0.64);
$black-disabled: rgba(0, 0, 0, 0.40);

$white-primary: rgba(255, 255, 255, 0.88);
$white-secondary: rgba(255, 255, 255, 0.64);
$white-disabled: rgba(255, 255, 255, 0.40);

// Grid
$gutter-x: 24;
$gutter-y: 24;

// Sizes
$size-sm: 560; //        x < 576
$size-md: 720; //  576 < x < 720
$size-lg: 1024; // 720  < x < 1024
$size-xl: 1240; // 1240 < x

// Fonts
%h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

%h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

%h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

%h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

%body1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

%body2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

%body3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

$boxShadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
$boxShadow2: 0 4px 4px rgba(0, 0, 0, 0.16);

:export {
  black: $black;
  white: $white;

  blue1: $blue-1;
  blue2: $blue-2;
  blue3: $blue-3;
  blue4: $blue-4;
  blue5: $blue-5;

  gray1: $gray-1;
  gray2: $gray-2;
  gray3: $gray-3;
  gray4: $gray-4;
  gray5: $gray-5;

  green1: $green-1;
  green2: $green-2;
  green3: $green-3;
  green4: $green-4;
  green5: $green-5;

  red1: $red-1;
  red2: $red-2;
  red3: $red-3;
  red4: $red-4;
  red5: $red-5;
  red6: $red-6;

  blackprimary: $black-primary;
  blacksecondary: $black-secondary;
  blackdisabled: $black-disabled;

  whiteprimary: $white-primary;
  whitesecondary: $white-secondary;
  whitedisabled: $white-disabled;

  sizesm: $size-sm;
  sizemd: $size-md;
  sizelg: $size-lg;
  sizexl: $size-xl;
}