@import '../../assets/scss/variables';

.reservation-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

@media (min-width: $size-md + 'px') {
  .reservation-container {
    max-width: 400px;
  }
}