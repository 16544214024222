@import '../../../assets/scss/variables';

@media (max-width: $size-md + 'px') {
  .button {
    align-self: flex-end;
  }
}

@media (min-width: $size-md + 'px') {
  .button-container {
    margin-bottom: 1rem;
  }

  .button {
    margin-left: 0 !important;
  }
}