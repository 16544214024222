.mbsc-timeline-column {
  // border-color: transparent !important;
}

.mbsc-timeline-parent {
  height: 50px !important;
}

.mbsc-timeline-row {
  height: 100px;
}