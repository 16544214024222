@import '../../assets/scss/variables';

.img-background {
  width: 100%;
  border-radius: 16px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &.contain {
    background-size: contain;
  }
}

.img {
  width: 24px;
  height: 24px;
}

.slider {
  position: relative;
  z-index: 9;

  .slider-images {}

  .slider-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    fill: $white-primary;
    cursor: pointer;

    &:hover {
      background: $gray-3;
    }
  }

  .slider-arrow {
    position: absolute;
    fill: $white-primary;
    top: calc(50% - 17px);
    height: 34px;
    width: 34px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    background: $black-secondary;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }
  }

  .slider-images {
    height: 208px;

    &>* {
      border: 1px solid $gray-5;
    }
  }

  &.full {
    position: fixed;
    top: 10vh;
    left: 10vw;
    height: 80vh;
    width: 80vw;
    background: $white;
    border-radius: 16px;
    box-shadow: $boxShadow;
    overflow-x: hidden;

    .slider-images {
      height: 80vh;

      &>* {
        border: none;
        border-radius: 0;
      }
    }
  }
}

.slider-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  cursor: default;
  z-index: 8;
}