.card-signature-container {
  width: 100%;
  height: 300px;
}

.signature {
  width: 100%;
  height: 200px;
  margin-top: -16;
  margin-left: -16;
  border-top-right-radius: 8;
  border-top-left-radius: 8;
}