@import './variables';

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding: 8px 16px;
  min-height: 56px;
  width: 100%;
  border-radius: 8px;
  user-select: none;
  text-decoration: none;
  border: 0;
  color: $black-primary;
  background-color: $gray-5;
  cursor: pointer;

  .subtitle {
      color: $black-secondary;
  }

  svg {
    fill: $black-primary;
  }

  &:hover, &.hover {
    color: $black-secondary;
    background: $white;
    box-shadow: 0 0 0 1px $gray-4 inset;
    border-radius: 4px;

    svg {
      fill: $black-secondary;
    }
  }

  &:active, &.active {
    color: $black-primary;
    background: $gray-4;
    box-shadow: none;
    border-radius: 16px;

    svg {
      fill: $black-primary;
    }
  }

  &:disabled, &.disabled {
    color: $black-disabled;
    background: $gray-5;
    box-shadow: none;
    border-radius: 8px;

    svg {
      fill: $black-disabled;
    }

    .subtitle {
      color: $black-disabled;
    }
  }

  &:focus, &.focus {
    color: $black-primary;
    background: $gray-5;
    box-shadow: 0 0 0 2px $gray-1 inset;
    border-radius: 8px;

    svg {
      fill: $black-primary;
    }
  }
}

.dark-theme {
  .navigation {
    color: $white-primary;
    background-color: $gray-1;

    .subtitle {
      color: $white-secondary;
    }

    svg {
      fill: $white-primary;
    }

    &:hover, &.hover {
      color: $white-secondary;
      background: $black;
      box-shadow: 0 0 0 1px $gray-2 inset;
      border-radius: 4px;

      svg {
        fill: $white-secondary;
      }
    }

    &:active, &.active {
      color: $white-primary;
      background: $gray-2;
      box-shadow: none;
      border-radius: 16px;

      svg {
        fill: $white-primary;
      }
    }

    &:disabled, &.disabled {
      color: $white-disabled;
      background: $gray-1;
      box-shadow: none;
      border-radius: 8px;

      svg {
        fill: $white-disabled;
      }

      .subtitle {
        color: $white-disabled;
      }
    }

    &:focus, &.focus {
      color: $white-primary;
      background: $gray-1;
      box-shadow: 0 0 0 2px $gray-5 inset;
      border-radius: 8px;

      svg {
        fill: $white-primary;
      }
    }
  }
}
