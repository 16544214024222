@import './variables';

.tabs {
  display: flex;
  align-items: center;
  user-select: none;
  border: 0;
  padding: 4px;
  border-radius: 28px;
  background: $white;
  box-shadow: 0 0 0 1px $gray-4 inset;

  .tab {
    @extend %body2;
    border: 0;
    padding: 12px 8px;
    border-radius: 24px;
    min-width: 75px;
    text-align: center;
    cursor: pointer;
    color: $black-primary;
    background: $white;
    transition: .2s ease;

    &.selected {
      background: $gray-1;
      color: $white-primary;
    }

    &:hover,
    &.hover {
      color: $black-secondary;

      &.selected {
        background: $gray-2;
        color: $white-secondary;
      }
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px $gray-2 inset;
    }
  }

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    box-shadow: 0 0 0 1px $gray-2 inset;
  }

  &:active,
  &.active,
  &:focus,
  &.focus {
    box-shadow: none;
    background: $gray-5;

    .tab {
      background: $gray-5;
      color: $black-primary;

      &.selected {
        background: $gray-1;
        color: $white-primary;
      }
    }
  }

  &:disabled,
  &.disabled {
    box-shadow: none;
    background: $gray-5;
    pointer-events: none;

    .tab {
      background: $gray-5;
      color: $black-disabled;
      pointer-events: none;

      &.selected {
        background: $gray-4;
        color: $black-disabled;
      }
    }
  }
}

.dark-theme {
  .tabs {
    background: $black;
    box-shadow: 0 0 0 1px $gray-2 inset;

    .tab {
      color: $white-primary;
      background: $black;
      transition: .2s ease;

      &.selected {
        background: $gray-5;
        color: $black-primary;
      }

      &:hover,
      &.hover {
        color: $white-secondary;

        &.selected {
          background: $gray-4;
          color: $black-secondary;
        }
      }

      &:focus,
      &.focus {
        box-shadow: 0 0 0 2px $gray-4 inset;
      }
    }

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px $gray-4 inset;
    }

    &:active,
    &.active,
    &:focus,
    &.focus {
      background: $gray-1;
      box-shadow: none;

      .tab {
        background: $gray-1;
        color: $white-primary;

        &.selected {
          background: $gray-5;
          color: $black-primary;
        }
      }
    }

    &:disabled,
    &.disabled {
      background: $gray-1;
      box-shadow: none;

      .tab {
        background: $gray-1;
        color: $white-disabled;

        &.selected {
          background: $gray-2;
          color: $white-disabled;
        }
      }
    }
  }
}