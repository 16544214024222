@import '../../../assets/scss/variables';

.PhoneInputInput {
  color: $black-primary;
  background-color: $gray-5;
  border-width: 0;
  border-radius: 8px;
  padding: 16px;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
}

.dark-theme {
  .PhoneInputInput {
    color: $white-primary;
    background-color: $gray-1;
  }
}