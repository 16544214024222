@import './variables';

.link {
  color: $blue-2;
  @extend %body2;
  text-decoration-line: none;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;

  svg {
    fill: $blue-2;
  }

  &:hover, &.hover {
    color: $blue-3;
    text-decoration-line: underline;

    svg {
      fill: $blue-3;
    }
  }

  &:active, &.active {
    color: $blue-1;
    text-decoration-line: underline;

    svg {
      fill: $blue-1;
    }
  }

  &:disabled, &.disabled {
    color: $black-disabled;
    text-decoration-line: none;

    svg {
      fill: $black-disabled;
    }
  }

  &:focus, &.focus {
    color: $blue-2;
    box-shadow: 0 0 0 2px $gray-2 inset;
    border-radius: 4px;

    svg {
      fill: $blue-2;
    }
  }
}

.dark-theme {
  .link {
    color: $white-primary;

    svg {
      fill: $white-primary;
    }

    &:hover, &.hover {
      color: $white-secondary;

      svg {
        fill: $white-secondary;
      }
    }

    &:focus, &.focus,
    &:active, &.active {
      color: $white-primary;

      svg {
        fill: $white-primary;
      }
    }

    &:disabled, &.disabled {
      color: $white-disabled;

      svg {
        fill: $white-disabled;
      }
    }
  }
}
