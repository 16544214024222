@import './variables';

h1, .h1 {
  @extend h1;
  margin: 0 0 16px;
}

h2, .h2 {
  @extend %h2;
  margin: 0 0 16px;
}

h3, .h3 {
  @extend %h3;
  margin: 0 0 16px;
}

h4, .h4 {
  @extend %h4;
  margin: 0 0 16px;
}

.text-body1 {
  @extend %body1;
  text-align: left;
}

.text-body2 {
  @extend %body2;
  text-align: left;
}

.text-body3 {
  @extend %body3;
  text-align: left;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
  margin-top: 0;
  margin-bottom: 8px;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
  font-weight: 600;
}


// Small
//
// Add the correct font size in all browsers

small {
  font-size: 12px;
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.

a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.text-danger {
  color: $red-1;
}

.text-success {
  color: $green-1;
}

.text-primary {
  color: $blue-2;
}

.text-secondary { color: $black-secondary; }
.dark-theme .text-secondary { color: $white-secondary; }

.text-disabled { color: $black-disabled; }
.dark-theme .text-disabled { color: $white-disabled; }

.text-decoration-none { text-decoration-line: none !important; }
.text-decoration-line-through { text-decoration-line: line-through !important; }

.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

.icon-small { height: 16px; width: 16px; }

.cursor-pointer { cursor: pointer !important; }
.cursor-default { cursor: default !important; }

.text-nowrap { white-space: nowrap; }
