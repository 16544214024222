@import './variables';

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.w-fit {
  width: fit-content !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-0 {
  width: 0 !important;
}

.row-none {
  margin-left: inherit !important;
  margin-right: inherit !important;
  margin-bottom: inherit !important;
}

.row-x {
  margin-left: -1rem;
  margin-right: -1rem;
}

.row-x2 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.row-y {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.row-y2 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.row-b {
  margin-bottom: -1rem;
}

.row-b2 {
  margin-bottom: -1.5rem;
}

.w-100.row-x {
  width: calc(100% + 2rem) !important;
}

.w-100.row-x2 {
  width: calc(100% + 3rem) !important;
}

.h-100 {
  height: 100vh !important;
}

.h-50 {
  height: 50vh !important;
}

.h-25 {
  height: 25vh !important;
}

.h-0 {
  height: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.d-sm-block {
  display: block !important;
}

@media (min-width: $size-md + 'px') {
  .flex-md-direction-row {
    flex-direction: row !important;
  }

  .flex-md-direction-column {
    flex-direction: column !important;
  }

  .row-md-none {
    margin-left: inherit !important;
    margin-right: inherit !important;
    margin-bottom: inherit !important;
  }

  .row-md-x {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .row-md-b {
    margin-bottom: -1rem;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-100.row-md-x {
    width: calc(100% + 2rem) !important;
  }

  .w-md-fit {
    width: fit-content !important;
  }

  .w-100.w-md-fit {
    width: fit-content !important;
  }

  .position-md-relative {
    position: relative !important
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .justify-md-content-center {
    justify-content: center !important;
  }

  .justify-md-content-start {
    justify-content: flex-start !important;
  }

  .justify-md-content-end {
    justify-content: flex-end !important;
  }

  .justify-md-content-between {
    justify-content: space-between !important;
  }

  .justify-md-content-around {
    justify-content: space-around !important;
  }

  .align-md-items-start {
    align-items: flex-start !important;
  }

  .align-md-items-end {
    align-items: flex-end !important;
  }

  .align-md-items-center {
    align-items: center !important;
  }

  .align-md-content-start {
    align-content: flex-start !important;
  }

  .align-md-content-end {
    align-content: flex-end !important;
  }

  .align-md-content-center {
    align-content: center !important;
  }

  .align-md-content-between {
    align-content: space-between !important;
  }

  .align-md-content-around {
    align-content: space-around !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: $size-lg + 'px') {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-fit {
    width: fit-content !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .justify-lg-content-start {
    justify-content: flex-start !important;
  }

  .justify-lg-content-end {
    justify-content: flex-end !important;
  }

  .justify-lg-content-center {
    justify-content: center !important;
  }

  .justify-lg-content-between {
    justify-content: space-between !important;
  }

  .justify-lg-content-around {
    justify-content: space-around !important;
  }

  .align-lg-items-start {
    align-items: flex-start !important;
  }

  .align-lg-items-end {
    align-items: flex-end !important;
  }

  .align-lg-items-center {
    align-items: center !important;
  }

  .align-lg-content-start {
    align-content: flex-start !important;
  }

  .align-lg-content-end {
    align-content: flex-end !important;
  }

  .align-lg-content-center {
    align-content: center !important;
  }

  .align-lg-content-between {
    align-content: space-between !important;
  }

  .align-lg-content-around {
    align-content: space-around !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: $size-xl + 'px') {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-fit {
    width: fit-content !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .justify-xl-content-start {
    justify-content: flex-start !important;
  }

  .justify-xl-content-end {
    justify-content: flex-end !important;
  }

  .justify-xl-content-center {
    justify-content: center !important;
  }

  .justify-xl-content-between {
    justify-content: space-between !important;
  }

  .justify-xl-content-around {
    justify-content: space-around !important;
  }

  .align-xl-items-start {
    align-items: flex-start !important;
  }

  .align-xl-items-end {
    align-items: flex-end !important;
  }

  .align-xl-items-center {
    align-items: center !important;
  }

  .align-xl-content-start {
    align-content: flex-start !important;
  }

  .align-xl-content-end {
    align-content: flex-end !important;
  }

  .align-xl-content-center {
    align-content: center !important;
  }

  .align-xl-content-between {
    align-content: space-between !important;
  }

  .align-xl-content-around {
    align-content: space-around !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}