@import 'variables';
// stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-appearance: none;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format("truetype"),
    url("../fonts/Inter-SemiBold.ttf") format("truetype"),
    url("../fonts/Inter-ExtraLight.ttf") format("truetype");
}

:root {
  font-size: 16px;
  --primary-h: 206; // hue
  --primary-s: 88%; // saturation
  --primary-l: 40%; // lightness

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
  margin: 0; // 1
  font-family: Inter system-ui, -apple-system, Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  color: $black-primary;
  background-color: $white;
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black-primary, 0); // 4
  overscroll-behavior-y: contain;
  overflow-x: hidden;
}

// scss-docs-end reboot-body-rules

.svg-icon {
  fill: $black-primary;
}

.svg-icon-primary {
  fill: $blue-2;
}


// Images and content

img,
svg {
  vertical-align: middle;
}


// Tables
//
// Prevent double borders

table {
  caption-side: bottom;
  border-collapse: collapse;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
  font-weight: 400; // 1
  text-align: inherit; // 2
  text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}


// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
  display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 0;
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

*:focus-visible {
  outline: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
  margin: 0; // 1
}

// Remove the inheritance of text transform in Firefox
button,
select {
  text-transform: none;
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
  resize: vertical; // 1
}

::-webkit-inner-spin-button {
  height: auto;
}

// Correct the outline style in Safari.
[type="search"] {
  outline-offset: -2px;
}

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
  padding: 0;
}

// Inherit font family and line height for file input buttons

::file-selector-button {
  font: inherit;
}

// 1. Change font properties to `inherit`
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
  font: inherit; // 1
  -webkit-appearance: button; // 2
}

// Remove border from iframe

iframe {
  border: 0;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
  display: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.hidden-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.box-shadow-filter {
  filter: drop-shadow($boxShadow);
}

.box-shadow-filter2 {
  filter: drop-shadow($boxShadow2);
}

.box-shadow {
  box-shadow: $boxShadow;
}

.box-shadow2 {
  box-shadow: $boxShadow2;
}

.box-shadow-none {
  filter: none;
  box-shadow: none;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.rounded-pill {
  border-radius: 50% !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.with-background {
  background: $white;
}

;

.dark-theme .with-background {
  background: $black;
}

;

.border-dark {
  box-shadow: 0 0 0 1px $gray-1 inset !important;
}

.dark-theme .border-dark {
  box-shadow: 0 0 0 1px inset !important;
}

.opacity-1 {
  opacity: 1;
}

;

.opacity-0 {
  opacity: 0;
}

;

.border-left-1 {
  border-left: 1px solid $gray-5
}

@media (max-width: $size-md + 'px') {
  .box-shadow-md-filter {
    filter: drop-shadow($boxShadow);
  }

  .box-shadow2-md-filter {
    filter: drop-shadow($boxShadow2);
  }

  .box-shadow-md {
    box-shadow: $boxShadow;
  }

  .box-shadow2-md {
    box-shadow: $boxShadow2;
  }

  .box-shadow-md-none {
    filter: none;
    box-shadow: none;
  }
}

@media (min-width: $size-md + 'px') {
  .border-left-md-1 {
    border-left: 1px solid $gray-5
  }
}