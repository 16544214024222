@import 'assets/scss/variables';

.menu-icon:hover {
  cursor: pointer;
}

.menu {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  background-color: $gray-5;
  border-radius: 10px;
  padding: 24px;
  gap: 16px;
}

.menu-item {
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-1;
}

.menu-item:hover {
  background-color: $gray-4;
  cursor: pointer;
}

.menu-item:visited {
  color: $gray-1;
}